.query-builder {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
.group {
  background: #fff;
  border: 1px solid #ced4da;
  padding: 10px;
}
.MuiInputBase-root {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
}
.MuiButton-root {
  font-family: 'Poppins', sans-serif !important;
}
.MuiMenuItem-root {
  font-family: 'Poppins', sans-serif !important;
  font-size: 13px !important;
}
.MuiAutocomplete-paper {
  font-family: 'Poppins', sans-serif !important;
  font-size: 13px !important;
}
